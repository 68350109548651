import { useParams } from "@reach/router"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Layout from "../../../components/templates/layout"
import Edit from "../../../components/views/retail/products/edit"

export async function getServerSideProps(context) {
  console.log("context: ", context.query.id)
  return {
    id: context.query.id, // will be passed to the page component as props
  }
}

const Product = ({ id }) => {
  console.log("id: ", id)
  // const params = useParams()
  // console.log("params: ", params)
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <Edit id={id} />
      </Layout>
    </DndProvider>
  )
}

export default Product
